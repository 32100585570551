<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <div v-html="registerInfo" />
    <v-text-field
      tabindex="1"
      v-model="registerLocal.username"
      label="Username"
      name="login"
      prepend-icon="mdi-account"
      :rules="[rules.required, rules.minLenght(6)]"
      type="text"
    />
    <v-text-field
      tabindex="2"
      v-model="registerLocal.email"
      label="Email"
      name="email"
      prepend-icon="mdi-email"
      :rules="[rules.required, rules.email]"
      type="text"
    />
    <v-text-field
      tabindex="3"
      v-model="registerLocal.email1"
      label="Verifica email"
      name="email"
      prepend-icon="mdi-email"
      :rules="[rules.required, rules.email, rules.sameAs(register, 'email', 'Email')]"
      type="text"
    />
    <vue-tel-input-vuetify
      ref="phone"
      tabindex="4"
      class="sso-tel-input"
      v-model="registerLocal.phone"
      v-bind="phoneProps"
    >
      <template #prepend>
        <v-icon
          ref="phoneIcon"
          v-text="'mdi-cellphone-android'"
          :color="phoneProps.valid ? '' : 'error'"
        />
      </template>
    </vue-tel-input-vuetify>
    <vue-tel-input-vuetify
      ref="phone2"
      tabindex="5"
      class="sso-tel-input"
      v-model="registerLocal.phone2"
      v-bind="phoneProps2"
    >
      <template #prepend>
        <v-icon
          ref="phoneIcon"
          v-text="'mdi-cellphone-android'"
          :color="phoneProps2.valid ? '' : 'error'"
        />
      </template>
    </vue-tel-input-vuetify>
    <v-text-field
      tabindex="6"
      v-model="registerLocal.password1"
      id="password"
      label="Password"
      name="password"
      prepend-icon="mdi-lock"
      :rules="[rules.required]"
      :append-icon="show.password1 ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show.password1 ? 'text' : 'password'"
      counter
      clearable
      @click:append="show.password1 = !show.password1"
    />
    <v-text-field
      tabindex="7"
      v-model="registerLocal.password2"
      id="password"
      label="Verifica password"
      name="password"
      prepend-icon="mdi-lock"
      :rules="[rules.required, rules.sameAs(register, 'password1', 'Password')]"
      :append-icon="show.password2 ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show.password2 ? 'text' : 'password'"
      counter
      clearable
      @click:append="show.password2 = !show.password2"
    />
    <v-switch
      tabindex="8"
      v-model="registerLocal.auth_privacy"
      :label="`Autorizzazione trattamento dati ( ${registerLocal.auth_privacy ? '': 'NON '} Autorizzo )`"
      name="auth_privacy"
      prepend-icon="mdi-book-information-variant"
      type="text"
    />
  </v-form>
</template>
<script>
import rules from '@/utils/rules.js'
export default {
  props: {
    registerInfo: {
      type: String,
      default: ''
    },
    register: {
      type: Object,
      default: () => ({
        app_login: null,
        app_password: null,
        auth_privacy: true,
        email: null,
        email1: null,
        password1: null,
        phone: null,
        phone2: null,
        password2: null,
        username: null,
        validation_code: null
      })
    }
  },
  data () {
    return {
      valid: false,
      rules,
      phoneProps: {
        label: 'Telefono',
        name: 'phone',
        clearable: true,
        onlyCountries: ['IT'],
        defaultCountry: 'IT',
        validCharactersOnly: true,
        rules: [this.checkPhone],
        valid: true,
        mode: 'national',
        dropdownOptions: {
          disabledDialCode: true
        },
        inputOptions: {
          showDialCode: false
        }
      },
      phoneProps2: {
        label: 'Conferma telefono',
        name: 'phone2',
        clearable: true,
        onlyCountries: ['IT'],
        defaultCountry: 'IT',
        validCharactersOnly: true,
        rules: [this.checkPhone2],
        valid: true,
        mode: 'national',
        dropdownOptions: {
          disabledDialCode: true
        },
        inputOptions: {
          showDialCode: false
        }
      },
      show: {
        password1: null,
        password2: null,
        app_password: null
      }
    }
  },
  computed: {
    registerLocal: {
      get () {
        return this.register
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
    global.rr = this
  },
  methods: {
    checkPhone (formattedNumber) {
      if (!formattedNumber) {
        this.phoneProps.valid = true
        return true
      }
      const phone = this.$refs?.phone.phoneObject || null
      if (phone) {
        this.phoneProps.valid = phone.isValid
        return phone.isValid || 'Numero non valido'
      }
    },
    checkPhone2 (formattedNumber) {
      let valid = rules.requiredIf(this.registerLocal, 'phone', 'Obbligatorio insieme al campo Telefono')(formattedNumber)
      if (valid === true) {
        valid = rules.sameAs(this.registerLocal, 'phone', 'Telefono')(formattedNumber)
      }
      this.phoneProps2.valid = valid === true
      return valid
    }
  }
}
</script>
<style lang="scss">
.sso-tel-input {
  .country-code {
    display: none;
  }
}
</style>
